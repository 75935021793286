import React, { useState, useEffect } from 'react'
import { graphql } from "gatsby"

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import "../../styles/main.scss"

const Offtwain = () => {
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 600)
  }, [])

  return loader ? (
    <div className="loader" />
  ) : (
    <Layout>
      <SEO
        title="Forcera"
        keywords={[`software development`, `ai`, `big data`, `cloud engineering`, `machine learning`, `data strategy`]}
      />
      <article>
        <div className="container">
          <h2>OfftwAIn - Offshore Solar Energy Digital Twin for Predictive Maintenance</h2>
          <figure>
            <img
             src={"/offtwain.png"}
            />
          </figure>
          <h3>GreenOffshoreTech Project</h3>
          <p>
          <a className="a-blue" href="https://greenoffshoretech.com/" target="_blank">GreenOffshoreTech</a> is a Cluster Facilitated Project with the purpose of supporting innovation in Small and Medium-sized Enterprises (SMEs), fostering development of the emerging Blue Economy industries by enabling new cross-sectorial and cross-border value chains based on shared challenges and the deployment of key enabling technologies (KET).
          </p>
          <p>
          This project facilitates the creation of new products, processes, or services with the ambition to make offshore production and transport green, clean and sustainable towards a resource-efficient economy and EU GreenDeal, while facing Reindustrialization.
          </p>
          <hr />
          <h3>OfftwAIn</h3>
          <p className="mt-2">
          The OfftwAIn project aims to create a web-based platform that combines Digital Twin for Real-time Monitoring & AI-based Simulation, targeting solely the offshore solar energy niche market to address the issues related to this power source and enhance the cost-effectiveness of the operation and maintenance, benefiting producers and subsequently leading to savings for end consumers.
          </p>
          <p>
          In this project, the technologies leveraged by FORCERA, can help offshore energy producers in several dimensions, materializing the four OfftwAIn innovation objectives: real-time monitoring, remote diagnostics, simulation and testing and predictive maintenance.
          </p>
          <figure>
            <img
             src={"/offtwain-arch.png"}
            />
            <figcaption>OfftwAIn Technological Architecture</figcaption>
          </figure>
          <h3>Expected Impact</h3>
          <p>
          The OfftwAIn Digital Twin and Predictive Maintenance solution can address several challenges in the target offshore solar energy production sector by providing real-time monitoring and analysis of system performance, enabling more efficient maintenance, and reducing downtime. In detail, this novel digital system will enable early detection of equipment failures, optimization of maintenance schedules, and improved decision-making, leading to an improvement in the reliability and efficiency of offshore solar energy systems, translating to reduced downtimes, improved energy production, and cost savings.
          </p>
          <figure className="ue-horizon-logo">
              <img
             src={"/horizon-2020.jpeg"}
              />
              <figcaption>This project has received cascading funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement No 101005541
              </figcaption>
            </figure>
        </div>
      </article>
    </Layout>
  )
}

export default Offtwain;

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`   